.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  height: 50vh;
  width: 50vw;
  background-color: var(--paper-color);
}

.result-block-container .result-block {
  opacity: 1;
}

/* Fixed navbar related styles */
body {
  padding-top: 0; /* The main container already has padding */
  margin-right: 0 !important; /* Prevent width changes on transitions */
  overflow-y: auto; /* Allow vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: var(--background-color);
  color: var(--text-primary);
}

/* Prevent layout shifts during page transitions */
#root,
#app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal scrolling only */
  background-color: var(--background-color);
  color: var(--text-primary);
}

/* Content container styles - prevent jiggling during page transitions */
.container {
  position: relative;
  overflow-x: hidden; /* Prevent horizontal scrolling only */
  margin-right: auto !important;
  margin-left: auto !important;
  flex: 1 0 auto; /* Use flexbox for proper content expansion */
  background-color: var(--background-color);
}

/* Add proper footer positioning */
footer {
  flex-shrink: 0; /* Prevent footer from shrinking */
  position: relative; /* Remove fixed positioning if any */
  width: 100%;
  background-color: var(--paper-color);
  color: var(--text-primary);
}

/* Stabilize transitions between routes */
.route-transition {
  position: relative;
  width: 100%;
}

/* Remove blue focus outlines across the application */
button:focus,
a:focus,
input:focus,
textarea:focus,
select:focus,
.MuiButtonBase-root:focus,
.MuiInputBase-root:focus,
.MuiOutlinedInput-root:focus,
.MuiTab-root:focus,
.MuiPaginationItem-root:focus,
.MuiSelect-root:focus,
[role="button"]:focus,
[role="tab"]:focus,
[tabindex]:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Ensure no webkit focus rings either */
*:focus {
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: transparent;
}
