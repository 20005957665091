/* Base Styles */
:root {
  --primary-color: #5731ff;
  --secondary-color: #ff71ce;
  --background-color: #121212;
  --paper-color: #1e1e1e;
  --code-background: #2d2d2d;
  --code-border: #444;
  --text-primary: #ffffff;
  --text-secondary: #aaaaaa;
}

body {
  font-family: "Roboto", sans-serif;
}

/* Environment Banner */
.environment-banner {
  display: flex;
  align-items: center;
  background-color: var(--paper-color) !important;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #333 !important;
}

.environment-label {
  font-weight: 600;
  margin-right: 16px;
  color: var(--text-primary) !important;
}

.environment-mode {
  font-size: 0.9rem;
  color: var(--text-secondary) !important;
}

.environment-value {
  background-color: var(--code-background) !important;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: "Roboto Mono", monospace;
  color: var(--secondary-color) !important;
  font-weight: 500;
  text-shadow: 0 0 5px rgba(255, 113, 206, 0.6);
  border: 1px solid var(--code-border) !important;
}

/* API Status Container */
.api-status-container {
  background-color: var(--paper-color) !important;
  padding: 16px 20px;
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid #333 !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.api-status-container:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.api-status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #333 !important;
  padding-bottom: 10px;
}

.api-status-header h5 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-primary) !important;
}

.api-status-indicator {
  font-size: 0.95rem;
  line-height: 1.6;
  color: var(--text-secondary) !important;
}

.status-checking {
  display: flex;
  align-items: center;
  color: #aaa !important;
}

.status-result {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.status-result.connected {
  color: #4caf50;
}

.status-result.disconnected {
  color: #f44336;
}

.status-icon {
  font-size: 0.8rem !important;
  margin-right: 8px;
}

.status-icon.checking {
  color: #ffa000;
  animation: blink 1.2s infinite;
}

.status-icon.connected {
  color: #4caf50;
}

.status-icon.disconnected {
  color: #f44336;
}

.status-timestamp {
  margin-top: 6px;
  color: #888 !important;
  font-size: 0.8rem;
  font-style: italic;
}

.api-url {
  background-color: var(--code-background) !important;
  padding: 3px 6px;
  border-radius: 4px;
  font-family: "Roboto Mono", monospace;
  font-size: 0.85em;
  color: var(--secondary-color) !important;
  font-weight: 500;
  text-shadow: 0 0 5px rgba(255, 113, 206, 0.6);
  border: 1px solid var(--code-border) !important;
}

@keyframes blink {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

/* Token Container */
.token-container {
  margin-bottom: 24px;
  font-size: 0.95rem;
  background-color: var(--paper-color) !important;
  border: 1px solid #333 !important;
  border-radius: 8px !important;
}

.token-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--secondary-color) !important;
}

.token-code {
  display: block;
  background-color: var(--code-background) !important;
  padding: 12px;
  border-radius: 6px;
  word-break: break-all;
  max-height: 120px;
  overflow-y: auto;
  font-family: "Roboto Mono", monospace;
  font-size: 0.85rem;
  border: 1px solid var(--code-border) !important;
  color: var(--secondary-color) !important;
  font-weight: 500;
  text-shadow: 0 0 5px rgba(255, 113, 206, 0.6);
  line-height: 1.5;
}

/* Styled token code for the new layout */
.token-code-styled {
  display: block;
  width: 100%;
  background-color: var(--code-background) !important;
  padding: 10px;
  border-radius: 4px;
  word-break: break-all;
  max-height: 100px;
  overflow-y: auto;
  font-family: "Roboto Mono", monospace;
  font-size: 0.85rem;
  color: var(--secondary-color) !important;
  font-weight: 500;
  text-shadow: 0 0 5px rgba(255, 113, 206, 0.6);
  line-height: 1.5;
  border: 1px solid var(--code-border) !important;
  margin-top: 5px;
}

/* Connection Details Styles */
.connection-details {
  margin-top: 14px;
  padding: 12px 14px;
  background-color: #1a1a1a !important;
  border-radius: 6px;
  font-size: 0.9rem;
  border: 1px solid #333 !important;
}

.details-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
  color: var(--text-secondary) !important;
}

.details-row:last-child {
  margin-bottom: 0;
}

.detail-label {
  font-weight: 500;
  margin-right: 10px;
  min-width: 90px;
  color: var(--text-secondary) !important;
}

.details-row code {
  background-color: var(--code-background) !important;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: "Roboto Mono", monospace;
  font-size: 0.85em;
  color: var(--secondary-color) !important;
  font-weight: 500;
  text-shadow: 0 0 5px rgba(255, 113, 206, 0.6);
  border: 1px solid var(--code-border) !important;
}

.dyno-tag {
  margin-left: 10px;
  background-color: #7e57c2;
  color: var(--text-primary);
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.75em;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.error-message {
  margin-top: 12px;
  color: #f44336 !important;
  font-size: 0.9rem;
  background-color: rgba(244, 67, 54, 0.1) !important;
  padding: 10px 14px;
  border-radius: 6px;
  border: 1px solid rgba(244, 67, 54, 0.3) !important;
}
